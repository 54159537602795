import Head from 'next/head';
import { useEffect, useState } from 'react';
import { RecoilRoot } from 'recoil';
import LogRocket from 'logrocket';
import { UserProvider } from '@auth0/nextjs-auth0';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import PageWrapper from '@/components/layout/PageWrapper';
import { PusherProvider } from '@/lib/context/PusherContext';
import { MessageProvider } from '@/lib/context/MessageContext';

import '@/styles/globals.css';
import '@/styles/vars.css';

function App({ Component, pageProps, status }) {
	const getLayout = Component.getLayout || ((page) => page);
	const queryClientOptions = { defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } } };
	const [queryClient] = useState(() => new QueryClient(queryClientOptions));

	useEffect(() => {
		if (process.env.NEXT_PUBLIC_ENV !== 'development') {
			LogRocket.init('sehawt/bc-ap-dashboard');
		}
	}, []);

	return (
		<>
			<Head>
				<meta name="viewport" content="initial-scale=1.0, width=device-width" />
				<meta name="robots" content="noindex" />
			</Head>
			<UserProvider>
				<MessageProvider>
					<PusherProvider>
						<RecoilRoot>
							<QueryClientProvider client={queryClient}>
								<PageWrapper status={status}>{getLayout(<Component {...pageProps} />)}</PageWrapper>
							</QueryClientProvider>
						</RecoilRoot>
					</PusherProvider>
				</MessageProvider>
			</UserProvider>
		</>
	);
}

export default App;
