import { useUser } from '@auth0/nextjs-auth0';
import useLogRocket from '@/lib/hooks/useLogRocket';
import Unauthorized from '@/pages/unauthorized';
import Spin from '@/components/ui/Spin';
import { BasicLogin } from './BasicLogin';

export default function Layout({ children }) {
	const { user, error, isLoading } = useUser();
	const { applyLogRocketUser } = useLogRocket();

	if (isLoading) return <Spin />;

	if (error) return <div>{error.message}</div>;

	if (!user) return <BasicLogin />;

	if (process.env.NEXT_PUBLIC_ENV !== 'development') {
		applyLogRocketUser(user);
	}

	if (!user?.privileges || user.privileges.length === 0 || !user?.org) return <Unauthorized />;

	return children;
}
