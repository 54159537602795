import { createContext, useContext, useEffect, useRef } from 'react';
import Pusher from 'pusher-js';

if (process.env.NEXT_PUBLIC_ENV === 'development') {
	Pusher.logToConsole = true;
}

const PusherContext = createContext({
	notification: null,
	showNotification: () => {},
	hideNotification: () => {}
});

function PusherProvider({ children }) {
	const pusher = useRef(null);

	useEffect(() => {
		const key = process.env.NEXT_PUBLIC_PUSHER_KEY;
		const options = { cluster: 'us2' };
		pusher.current = new Pusher(key, options);
	}, []);

	return <PusherContext.Provider value={{ pusher }}>{children}</PusherContext.Provider>;
}

function usePusher() {
	const context = useContext(PusherContext);

	if (!context) {
		// ! Fail fast if not within a PusherProvider
		throw new Error('usePusher must be used within a PusherProvider');
	}

	const { pusher } = context;

	return pusher;
}

export { PusherProvider, usePusher };
